import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import GhostContentAPI from '@tryghost/content-api'

import { Navbar, Footer } from './lab201'
import {setCursorMode, handleCursorScroll, handleCursorMove} from './utils'
import './styles/posts.css'

const api = new GhostContentAPI({
    url: 'https://blog.lab201.com',
    key: '43e5445edb2c9d9e4f8c8ce161',
    version: "v5.0"
})

export function Post () {
  const cursorRef = useRef({mouseY: 0, scrollY: 0})
  const { slug } = useParams()
  const [ post, setPost ] = useState(null)
  const [ isBlog, setIsBlog] = useState(false)
  const [ progress, setProgress ] = useState(0)

  const handleProgressScroll = (e) => {
    let h = document.documentElement;
    let st = h.scrollTop || document.body.scrollTop;
    let sh = h.scrollHeight || document.body.scrollHeight;

    let percent = st / (sh - h.clientHeight) * 100;
    setProgress(percent)
  }

  useEffect(() => {
    const $cursor = document.querySelector('.cursor')
    document.body.addEventListener('mousemove',
        (e) => handleCursorMove(e, $cursor, cursorRef))
    window.addEventListener('scroll', 
        (e) => handleCursorScroll(e, $cursor, cursorRef));
    window.addEventListener('scroll', handleProgressScroll)
    api.posts.read({slug: slug}, {include: 'tags,authors'})
    .then((post) => {
      setPost(post)
      if (post.tags.some(t => t.slug === 'blog')) setIsBlog(true)
      setCursorMode('.post__body figure', 'cursor--noblend')
    })
  }, [slug])


  return (
    <>
    <div className="post__scroll" style={{width: progress + '%'}}></div>
    <div className="post__container"> 
    <Navbar active={isBlog ? 'blog' : 'work'} hasLogo={true} dark={false} isFixed={true}/>

      <div className="post">
        <div className="post__info">
          {post?.tags && 
            post.tags.map((tag) => {
              return (
                <div className="post__tag" key={tag.id}>{tag.name}</div>
              )
            })
          }
          <div className="post__date">
            {post?.published_at && 
                new Date(post.published_at).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric'})}
          </div>
        </div>

        <div className="post__title">{post?.title}</div>
        <div className="post__body" dangerouslySetInnerHTML={{__html: post?.html}}></div>

      </div>

      <Footer />
    </div>
    </>
  )
}
