import React, {useState, useEffect, useRef} from 'react'
import { useLocation } from 'react-router-dom';
import GhostContentAPI from '@tryghost/content-api'

import { Navbar, Footer} from './lab201'
import {setCursorMode, handleCursorScroll, handleCursorMove} from './utils'

import './styles/blog.css'

import searchIcon from './assets/icon__search.svg'

const api = new GhostContentAPI({
    url: 'https://blog.lab201.com',
    key: '43e5445edb2c9d9e4f8c8ce161',
    version: "v5.0"
})

function Tile({work}) {
  const previewRef = useRef(null)
  const [style, setStyle] = useState({})

  const clampLineHeight = () => {
    if (previewRef && previewRef?.current) {
      const pos = previewRef.current.getBoundingClientRect()
      const clamp = Math.floor(pos.height / 24)
      const elasticHeight = clamp *  24 
      const newStyle  = {
        'height': elasticHeight + 'px',
        'WebkitLineClamp': clamp,
        'marginTop': 'auto'}
      setStyle(newStyle)
    }
  }

  return (
    <a className="blog__tile-container" href={"/content/" + work.slug}>
      <div className={"blog__tile"}>
        <img src={work.feature_image} onLoad={() => clampLineHeight()}
             alt="Blog post feature." />
        <div className="blog__tile-meta">
          <div className="blog__tile-tag">
            {work.primary_tag.name}
          </div>
          <div className="blog__tile-date">
            {new Date(work.published_at).toLocaleString('en-US', { month: 'short', day: 'numeric'})} · 
          </div> 
          <div className="blog__tile-readtime">
            {' ' + work.reading_time + ' min read'}
          </div>
        </div>
        <div className="blog__tile-title">
          {work.title}
        </div>
        <div className="blog__tile-preview" style={style} ref={previewRef}>
          {work.excerpt}
        </div>
      </div>
    </a>
  )
}

function Tiles ({works}) {
  useEffect(() => {
    setCursorMode('.blog__content', 'cursor--noblend')
    setCursorMode('.blog__tile', 'cursor--noblend-hover')
  },  [works])
  return (
    <div className="blog__content">
      {works.map((work) => {
        return (
          <Tile work={work} key={work.id} />
        )
      })}
    </div>
  )
}

export function Blog () {
  const cursorRef = useRef({mouseY: 0, scrollY:0})
  const { state } = useLocation()
  const [search, setSearch] = useState('')
  const [tags, setTags] = useState([])
  const [works, setWorks] = useState([])
  const [activeTag, setActiveTag] = useState(null)

  const setTagCount = (tags) => {
    let tagData = []
    for (const tag of tags) {
        const options = {fields: 'id',
                         filter: ('tags:blog+tags:' + tag.slug)}
        api.posts.browse(options)
        .then((res) => {
          if ((tag.slug !== 'blog') && (res.length > 0)) {
            tagData.push({name: tag.name,
                          count: res.length,
                          id: tag.id,
                          slug: tag.slug})
          }
        })
    }
    setTags(tagData)
  }

  const updateTiles = (tag) => {
    setActiveTag(tag)
    const options = (tag ? {include: 'tags', filter: ('tags:blog+tags:' + tag)} 
                         : {include: 'tags', filter: ('tags: blog') })

    api.posts.browse(options)
    .then((posts) => {
      setWorks(posts)
   })
  }

  const searchPosts  = (e) => {
    e.preventDefault()

    setActiveTag(null)
    const options = (search ? {include: 'tags', filter: `tags:blog+title:~'${search}'`}
                            : {include: 'tags', filter: `tags:blog`})

    api.posts.browse(options)
    .then((posts) => {
      setWorks(posts)
   })
  }


  useEffect(() => {
    const $cursor = document.querySelector('.cursor')

    window.history.replaceState({}, document.title)
    document.body.addEventListener('mousemove', 
        (e) => handleCursorMove(e, $cursor, cursorRef));
    window.addEventListener('scroll',
        (e) => handleCursorScroll(e, $cursor, cursorRef));

    setCursorMode('.navbar__link', 'lang-hover')

    api.tags.browse({include: 'count.posts'})
      .then((tags) => {
        setTagCount(tags)
        setCursorMode('.blog__tag', 'lang-hover')
        updateTiles(state?.active)
      })
      .catch((err) => {console.error(err)})
    }, [state])

  return (
    <>
      <Navbar active={'blog'} hasLogo={true} dark={false}/>
      <div className="blog__container">

        <div className="blog__controller">
        <div className="blog__tags">
          <div className={"blog__tag blog__tag--all" + 
                          (!activeTag ? " blog__tag--active" : "")}
              onClick={() => updateTiles(null)}>
            All
          </div>
          {tags.map((tag) => {
            return (
              <div className={"blog__tag" + (activeTag === tag.slug 
                                             ? ' blog__tag--active' : '')} 
                   key={tag.id} onClick={() => updateTiles(tag.slug)}>
                <div className="blog__tag-name">{tag.name}</div>
                <div className="blog__tag-count"> ({tag.count})</div>
              </div>
            )
          })}
          </div>

          <form className="blog__search" onSubmit={searchPosts}>
            <input type="text" placeholder="Search" value={search} 
                   onChange={(e) => setSearch(e.target.value)}/>
            <button type="submit" aria-label="Search.">
              <img src={searchIcon} alt="Search icon." />
            </button>
          </form>
        </div>

        <Tiles works={works} />
      </div>

      <Footer lang={'en'} />
    </>
  )
}
