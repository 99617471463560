export function setCursorMode (targetClass, cursorClass) {
    const $cursor = document.querySelector('.cursor')
    const $targets = document.querySelectorAll(targetClass)

    $targets?.forEach((target) => {
      target.addEventListener('mouseover', () => {
        $cursor.classList.add(cursorClass)
      })
    })

    $targets?.forEach((target) => {
      target.addEventListener('mouseleave', () => {
        $cursor.classList.remove(cursorClass)
      })
    })
  }

export function handleCursorScroll (e, $cursor, ref) {
    const scrollY = document.querySelector('html').scrollTop
    const offset = ref.current.scrollY - scrollY
    $cursor.style.top = (ref.current.mouseY - offset) + 'px'
}

export function handleCursorMove (e, $cursor, ref) {
    ref.current.scrollY = document.querySelector('html').scrollTop
    var x = e.pageX;
    var y = ref.current.mouseY = e.pageY;
    $cursor.style.left = x + 'px';
    $cursor.style.top = y + 'px';
}
