import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './styles/index.css';

import ErrorPage from "./404";
import { Home } from './home';
import { HomeKR } from './home.kr'
import { About } from './about'
import { Work } from './work'
import { Post } from './post'
import { Blog } from './blog'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/kr',
    element: <HomeKR />,
  },
  {
    path: 'contact',
    element: <Home page='contact' />,
  },
  {
    path: '/kr/contact',
    element: <HomeKR page='contact' />,
  },
 
  {
    path: 'about',
    element: <About />,
  },
  {
    path: '/kr/about',
    element: <About lang={'kr'} />,
  }, 
  {
    path: 'work',
    element: <Work />,
  }, 
  {
    path: 'blog',
    element: <Blog />,
  }, 
  {
    path: '/content/:slug',
    element: <Post />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
