import { useRouteError } from "react-router-dom";
import './styles/error.css'

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="error-container">
      <div className="error">
        <div className="error-title">
          oh, <span className="error-em">f*ck.</span>
        </div>
        <div className="error-message">
          We couldn't find that page.<br />
          Have you tried turning it off and on again?
        </div>
        <a href="/" className="error-redirect">
          let's go home →
        </a>
      </div>
    </div>
  );
}
