import { useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import 'jquery.easing'

import scribble from './assets/scribble.svg'
import { Navbar, Footer } from './lab201'

import './styles/base.css'
import './styles/home-m.css';
import './styles/home.css';

const _animateText = function (aTime, sTime, wordIndex, hasRun) {
  const wordOffset = '50px'
  const wordlist = ['stories', 'design', 'engineering', 'branding',
                    'research', 'interface', 'creative',
                    'experience', 'digital', 'graphics', 'strategy',
                    'positioning', 'identity', 'product', 'development',
                    'everything']

  let textElement = $('#active-word')
  let currentWord = wordlist[wordIndex]

  textElement.animate({'top': ('-'+wordOffset), 'opacity': 0}, {easing: 'linear', duration: aTime, complete: () => {
    textElement.text(currentWord)
    let easing = 'easeInOutCirc'
    easing = 'linear'
    if (wordIndex === (wordlist.length - 1)) {
      textElement.css('font-weight', '900')
      textElement.css({'top': wordOffset}).animate({'top': '0', 'opacity': '1'}, {easing: easing, duration: aTime})
      .animate({marginTop: '-5px', duration: 40, easing: 'linear'})

      textElement.css('filter', 'none')
      textElement.animate({marginTop: '0px'}, {duration: 250, easing: 'easeInExpo', complete: () => {
        $('#underline').animate({width: '55vw'}, {duration: 450, easing: 'easeInOutExpo'})
      }})
        
      wordIndex = 0;
      return
    } else {
      textElement.css({'top': wordOffset}).animate({'top': '0px', 'opacity': '1'}, {easing: easing, duration: aTime})
    }

    setTimeout(() => {
      wordIndex++
      if (wordIndex === wordlist.length) { return }
      else { _animateText(aTime + 0, sTime, wordIndex) }
    }, sTime)
  }});
}

function Home({page}) {
  let canScroll = true
  let currentSection = 0;
  let sections = []
  let sectionContainer = []
  let touchStart = 0;
  let touchEnd = 0;
  let hasRun = false

  const performScroll = function (scrollDir) {
    currentSection += scrollDir;

    // Make sure we stick to real sections and don't scroll out of it.
    if (currentSection >= sections.length - 1) {
      currentSection = sections.length - 1;
    }
    if (0 > currentSection) {
      currentSection = 0;
    }

    // perform the 'scroll'
    if (currentSection === 3) {
      sectionContainer.style.top = 'calc(-200vh - 250px)'
    } else { 
    sectionContainer.style.top = (currentSection * -100) + 'vh';
    if (currentSection === 0) {
      sections[0].style.background = '#121212'
      sections[1].style.background = '#121212'
      sections[2].style.background = '#121212'
      $('.tile-badge').css('background-color', '#121212')
      $('.tile-badge').css('color', '#121212')
      $('#and-more').css('color', '#121212')
      $('.tile-group--1 .about-tile').css('left', '50px')
      $('.tile-group--2 .about-tile').css('right', '50px')
    } else if (currentSection === 1) {
      sections[0].style.background = '#FCFCFC'
      sections[1].style.background = '#FCFCFC'
      sections[2].style.background = '#FCFCFC' 
      $('.tile-badge').css('background-color', '#E20000')
      $('.tile-badge').css('color', '#FCFCFC')
      $('#and-more').css('color', '#FCFCFC')
      if (!hasRun) _animateText(35, 0, 0)
      hasRun = true

      $('.tile-group--1 .about-tile').css('left', '0px')
      $('.tile-group--2 .about-tile').css('right', '0px')
    } else {
      sections[0].style.background = '#121212'
      sections[1].style.background = '#121212'
      sections[2].style.background = '#121212' 
      $('.tile-badge').css('background-color', '#121212')
      $('.tile-badge').css('color', '#121212')
      $('#and-more').css('color', 'black')
      $('.tile-group--1 .about-tile').css('left', '50px')
      $('.tile-group--2 .about-tile').css('right', '50px')
      setTimeout(() => {$('#scribble').addClass('stroke')}, 1000) 
    }
    }
  }


  useEffect(()=>{
    const controller = new AbortController()
    $('.contact-email').mouseenter(() => $('.cursor').addClass('contact-hover'))
    $('.contact-email').mouseleave(() => $('.cursor').removeClass('contact-hover'))

    $('.about-tile').mouseenter(() => $('.cursor').addClass('lang-hover'))
    $('.about-tile-container').mouseenter(() => $('.cursor').addClass('cursor--noblend'))
    $('.about-tile').mouseleave(() => $('.cursor').removeClass('lang-hover'))
    $('.about-tile-container').mouseleave(() => $('.cursor').removeClass('cursor--noblend'))

    $('.lang__option').not('.lang--active').mouseenter(() => $('.cursor').addClass('lang-hover'))
    $('.lang__option').not('.lang--active').mouseleave(() => $('.cursor').removeClass('lang-hover'))


    sections = document.querySelectorAll('.page, .footer');
    sectionContainer = document.querySelector('.viewport');

    if (page) {
    currentSection = 2
    sectionContainer.style.transition = 'unset'
      sections[0].style.background = '#121212'
      sections[1].style.background = '#121212'
      sections[2].style.background = '#121212' 
      $('.tile-badge').css('background-color', '#121212')
      $('.tile-badge').css('color', '#121212')
      $('#and-more').css('color', 'black')
      $('.tile-group--1 .about-tile').css('left', '50px')
      $('.tile-group--2 .about-tile').css('right', '50px')
      setTimeout(() => {$('#scribble').addClass('stroke')}, 1000) 
 
    sectionContainer.style.top = (currentSection * -100) + 'vh';
    setTimeout(() => {
    sectionContainer.style.transition = 'top 1s ease'
    }, 1000)
    }

    document.body.onmousemove = function(e) {
      document.documentElement.style.setProperty('--x', (e.clientX+window.scrollX) + 'px');
      if (currentSection === 3) {
      document.documentElement.style.setProperty('--y',
        (e.clientY+window.scrollY+(window.innerHeight * 2 + 250)) + 'px');
      } else {
        document.documentElement.style.setProperty('--y',
          (e.clientY+window.scrollY+(window.innerHeight * currentSection)) + 'px');
      }

    }

    document.addEventListener('touchstart', (event) => {
      touchStart = event.changedTouches[0].clientY;
    }, {signal: controller.signal});

    document.addEventListener('touchend', (event) => {
      touchEnd = event.changedTouches[0].clientY;

      if (!canScroll) {
        touchStart = 0
        touchEnd = 0
         return;
      }


      canScroll = false;
        setTimeout(() => {
        canScroll = true;
      }, 1200);


      if ((touchStart - touchEnd) > 20) {
        performScroll(1);
      } else if ((touchEnd - touchStart) > 20){
        performScroll(-1);
      }
    }, {signal: controller.signal});

    document.addEventListener('wheel', (event) => {

      event.preventDefault();
      event.stopPropagation();

      if (!canScroll) {
         return;
      }

      canScroll = false;
        setTimeout(() => {
        canScroll = true;
      }, 1200);

      let scrollDir = event.deltaY > 0 ? 1 : -1;
      performScroll(scrollDir)
    }, { passive: false, signal: controller.signal })

    return () => {controller.abort()}
  }, [])

  return (
    <div className="viewport">
      <Hero />
      <Capabilities />
      <Contact />
      <Footer active='' lang={'en'} />
    </div>
  )
}

function Hero () {
  return (
    <div id="page-1" className="page" data-section-name="top">
      <Navbar lang="en" />

      <div className="hello">
        <div className="hero">
          <span className="hero-text">
            Designing
          </span>
          <span className="hero-extra"></span>
          <div className="hero-text-name">
            Human <br />
            Experiences.
          </div>
        </div>
        <div className="hero-subheader">
          <span className="entry">læb tuː-oʊ-wʌn: </span>
          <span className="definition">We believe in creating things that help people, and in telling the stories that need to be told.
We believe that elegant solutions come from elegant questions, and that true innovation is born when passion and knowledge and creativity collide.
          </span>
        </div>
      </div>
    </div>
  )
}

function Capabilities () {
  const [productDesignCount, setProductDesignCount] = useState(undefined)
  const [brandingCount, setBrandingCount] = useState(undefined)

  const [uxuiCount, setUxuiCount] = useState(undefined)
  const [webMobileCount, setWebMobileCount] = useState(undefined)
  const [dataScienceCount, setDataScienceCount] = useState(undefined)

  const [aimlCount, setAimlCount] = useState(undefined)
  const [developmentCount, setDevelopmentCount] = useState(undefined)

  const setTagCount = (res, slug, setter) => {
    const tagObj = res.tags.find(tag => {return tag.slug === slug})
    if (tagObj && tagObj.count && tagObj.count.posts) {
      setter(tagObj.count.posts)
    }
  }

  useEffect(() => {
    const key = '43e5445edb2c9d9e4f8c8ce161'
    fetch('https://blog.lab201.com/ghost/api/content/tags/?key=' + key + '&include=count.posts')
    .then(response => response.json())
    .then(res => {
      setTagCount(res, 'product-design', setProductDesignCount)
      setTagCount(res, 'branding', setBrandingCount)

      setTagCount(res, 'ux-ui', setWebMobileCount)
      setTagCount(res, 'ux-ui', setUxuiCount)
      //setTagCount(res, 'ux-ui', setUxuiCount)
      setTagCount(res, 'research', setDataScienceCount)

      setTagCount(res, 'research', setAimlCount)
      //setTagCount(res, 'ai-ml', setAimlCount)
      setTagCount(res, 'development', setDevelopmentCount)
    })
  }, [])

  return (
    <div id="page-2" className="page" data-section-name="work">
      <div className="what-we-do">
        <div id="capabilities">
          <span id="work-1">We </span>
          <span className="work-italic">dabble </span>
          <span id="work-2">in<br /></span>
          <div id="work-line-2">
            <span id="line-2">a bit of </span>
            <svg width="1" height="1">
              <defs>
                <filter id="blur">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="0 10" />
                </filter>
              </defs>
            </svg>

            <span id="active-word"> dreaming</span>
            <span id="underline" className="underline-en"></span>
          </div>
        </div>

        <div className="about-tile-container">
          <div className="tile-group tile-group--1" groupid="1">
            <CapabilityTile name={"Product Design"} slug="product-design" count={productDesignCount} />
            <CapabilityTile name={"Brand Identity"} slug="branding" count={brandingCount} />

         </div>

          <div className="tile-group tile-group--2" groupid="2">
            <CapabilityTile name={'Web/Mobile'} slug="ux-ui" count={webMobileCount} />
            <CapabilityTile name={'UI/UX'} slug="ux-ui" count={uxuiCount} />
            <CapabilityTile name={'Data Science'} slug="research" count={dataScienceCount} />
          </div>

          <div className="tile-group tile-group--1" groupid="3">
            <CapabilityTile name={"AI/ML"} slug="research" count={aimlCount} />
            <CapabilityTile name={"Software"} slug="development" count={developmentCount} />
            <div id="and-more" className="about-tile about-tile-rev p1">
              and more
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function CapabilityTile ({name, count, slug}) {
  const navigate = useNavigate()
  const badge = count ? (<div className="tile-badge">{count}</div>) : ''
  const goToWork = (tag) => {
    navigate("work", { state: { active: tag } });
  }
  return (
    <div className="about-tile" onClick={() => goToWork(slug)}>
      { name }
      { badge }
    </div>
  )
}

function Contact () {
  return (
    <div id="page-3" className="page" data-section-name="contact">
      <div className="cool-shit">
        <span id="contact">
          <span className="contact-regular">Let’s </span>
          <span className="contact-bold">build <br /></span>
          <span className="contact-italic"> some 
            <span className="contact-semi"> cool
              <span className="kern-class"></span> sh<span id="star">*</span>t
            </span>
          </span>
          <span className="contact-bold"><br />together</span>
        </span>
        <div id="say-hello">We are easy to talk to,<br />feel free to say hello!</div>
        <div className="contact-email"><a id="contact-email" href="mailto:hello@lab201.com">hello@lab201.com</a></div>
        <img className="static-scribble" src={scribble} 
             width="230" height="17" alt="" />

        <svg id="scribble-svg" className="text" width="100%" aria-hidden="true">
          <path id="scribble" className="" style={{transform: 'translateY(-10px) scale(1.375)'}} fill="none" stroke="currentColor" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M1.7 10.2c76.5 4.4 153.6-9.7 229.8-4.1 5.4.4 100.4 2.1 11.7 1.6-67.3 1.7-134.5 2.5-201.2 11.5l87.7-.9c35.2-.4 100.8-.7 104.9 4.6"></path>
        </svg>
      </div>
    </div>
  )
}



export {Home, Navbar};
