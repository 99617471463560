import React, { useEffect, useState, useRef } from 'react'
import { Player } from '@lottiefiles/react-lottie-player';
import { setCursorMode } from './utils'

import './styles/base.css'
import logo from './assets/logo.svg'
import logoLight from './assets/logo--light.svg'
import logoH from './assets/logo/horizontal-lt.svg'

import menuAnim from './assets/hamburger.json'
import menuAnimDark from './assets/hamburger--dark.json'

import sendIcon from './assets/send.svg'
import alertIcon from './assets/alert.svg'
import checkIcon from './assets/check.svg'

export function Navbar ({active, lang=false, hasLogo=false, dark=true, isFixed=false}) {
  const [menuActive, setMenuActive] = useState(false)
  const [langActive, setLangActive] = useState(true)
  const menuRef = useRef(null)

  const getNavClass = () => {
    return ((!hasLogo ? "navbar" : "navbar navbar--logo") + 
           (!dark ? ' navbar--light' : ''))
  }

  const getLinkClass = (link) => {
    return ('navbar__link' + (link === active ? ' navbar__link--active' : '') + (menuActive ? ' responsive' : ''))
  }

  const getHref = (page, lang) => {
    if (lang === 'kr') return ('/kr/' + page)
    return ('/' + page)
  }

  useEffect(() => {
    document.querySelector('.lang__option:not(.lang--active)')?.addEventListener('mouseenter', 
        () => document.querySelector('.cursor').classList.add('lang-hover'))
    document.querySelector('.lang__option:not(.lang--active)')?.addEventListener('mouseleave', 
        () => document.querySelector('.cursor').classList.remove('lang-hover'))

    document.querySelectorAll('.navbar__link').forEach((link) => {
      link.addEventListener('mouseover', () => {
        document.querySelector('.cursor').classList.add('lang-hover')
      })
    })
    document.querySelectorAll('.navbar__link').forEach((link) => {
      link.addEventListener('mouseleave', () => {
        document.querySelector('.cursor').classList.remove('lang-hover')
      })
    })
    setCursorMode('.navbar__home img', 'lang-hover')
  }, [])
  
  return (
    <>
      <div className="cursor"></div>
    <div className={"navbar__container" + (isFixed ? ' navbar__container--fixed': '')}>
      <div className={getNavClass()}>
        { hasLogo ? (
          <a className={"navbar__home" + (menuActive ? ' responsive' : '')} href="/">
            <img src={dark ? logoLight : logo} alt="Lab 201 logo."/>
          </a> 
        ) : (
          <div className={"nav-home" + (!langActive ? ' responsive': '')}>
            <div className="lang__toggle">
              <a className={"lang__option lang__en" + (lang === 'en' ? ' lang--active' : '')} 
                  href={active ? "/" + active : '/'}>English</a>
              <div className="lang__divider">|</div>
              <a className={"lang__option lang__kr" + (lang === 'kr' ? ' lang--active' : '')} 
                  href={active ? "/kr/" + active : '/kr/'}>한국어</a>
            </div>
          </div>
        )}

        <div className={"navbar__links" + (menuActive ? ' responsive' : '')}>
          {(active && lang) && <a className={getLinkClass('home')} href={(lang === 'kr') ? '/kr' : '/'}>Home</a>}
          <a className={getLinkClass('about')} href={getHref('about', lang)}>About</a>
          <a className={getLinkClass('work')} href="/work">Work</a>
          <a className={getLinkClass('blog')} href="/blog">Blog</a>
          <a className={getLinkClass('contact')} href={getHref('contact', lang)}>Contact</a>
          <button className="navbar__burger" aria-label="Navbar mobile menu."
            onClick={() => { 
              setMenuActive(!menuActive)
              if (langActive) setLangActive(false)
              menuRef.current.play()
            }}>
            
            <Player className="navbar__burger-icon" mode="normal" 
                ref={menuRef}
                keepLastFrame={true}
                onEvent={event => {
                  if (event === 'complete') {
                    menuRef.current.setPlayerDirection(menuActive ? -1 : 1);
                    if (!menuActive && !langActive) setLangActive(true)
                  }
                }}
                src={dark ? menuAnim : menuAnimDark}>
            </Player>

          </button>
        </div>
      </div>
      {hasLogo && <div className={"navbar__divider" + (dark ? ' navbar__divider--dark' : '')} />}
    </div>
    </>
  )
}

export function Footer ({active, lang='en', dark=true}) {
  const [email, setEmail] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [statusIcon, setStatusIcon] = useState(sendIcon)

  const submitForm = async (email) => {
    const data = { email: email }
    const url = 'https://01djzssvg8.execute-api.us-west-1.amazonaws.com/prod/contact-201'

  try {
    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "text/plain",
        },
        body: JSON.stringify(data),
    });
   if (response.status === 200) {
      setStatusIcon(checkIcon)
      setEmail('Subscribed!')
    }
  } catch (error) {
    setStatusIcon(alertIcon)
    setErrorMsg('Check your email address again.')
  }

  }
  const getHref = (page, lang) => {
    if (lang === 'kr') return ('/kr/' + page)
    return ('/' + page)
  }

  useEffect(() => {
    document.querySelectorAll('.footer__link').forEach((link) => {
        link.addEventListener('mouseover', () => document.querySelector('.cursor').classList.add('lang-hover'))
    })
    document.querySelectorAll('.footer__link').forEach((link) => {
      link.addEventListener('mouseleave', () => document.querySelector('.cursor').classList.remove('lang-hover'))
    })

    document.querySelectorAll('.footer__input-container').forEach((link) => {
        link.addEventListener('mouseover', () => document.querySelector('.cursor').classList.add('lang-hover'))
    })
    document.querySelectorAll('.footer__input-container').forEach((link) => {
      link.addEventListener('mouseleave', () => document.querySelector('.cursor').classList.remove('lang-hover'))
    })
 
 
  }, [])

  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__t">
          <div className="footer__l">
            <div className="footer__logo"></div>
            { false && <img className="footer__logo" src={logoH} alt="Lab 201 logo." />}
            <div className="footer__links">
              <a className="footer__link" href={getHref('about', lang)}>About</a>
              <a className="footer__link" href="/work">Work</a>
              <a className="footer__link" href="/blog">Blog</a>
              <a className="footer__link" href={getHref('contact', lang)}>Contact</a>
              {lang === 'en' 
                ? (<a className="footer__link footer__link-lang" href={getHref(active, 'kr')}>한국어</a>) 
                : (<a className="footer__link footer__link-lang" href={getHref(active, 'en')}>English</a>)}
            </div>
          </div>
          <div className="footer__r">
            <form className="footer__contact-form" onSubmit={(e) => {e.preventDefault(); return submitForm(email)}}>
              <div className="footer__input-label">Stay up to date</div>
              <div className="footer__input-container">
                <input className="footer__input" placeholder="Your email adress" name="email" type="email" 
                  value={email} onChange={e => { 
                    setEmail(e.target.value)
                    setErrorMsg('')
                    setStatusIcon(sendIcon)
                  }}/>
                <button className="footer__contact-btn" type="submit"
                        aria-label="Subscribe to our updates!">
                  <img className="footer__contact-btn-icon" src={statusIcon} 
                       width="18" height="18" alt="" />
                </button>
              </div>
              <div className="footer__error-msg">{errorMsg}</div>
            </form>
          </div> 
        </div>
        <div className="footer__b">
          <div className="footer__copyright">
            © 2024 Lab 201 LLC. All rights reserved
          </div>
        </div>
      </div>
    </div>
  )
}

